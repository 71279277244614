<template>
  <div class="hostingPlatform">
    <div class="home_top"></div>
    <div class="hostingPlatform_cen">
      <div class="summaryData">
        <div class="summaryData_title">
          <img src="../../assets/images/hostingPLatform/prompt.png" alt="">
          <p>以下数据托管已到期，请您确定是否继续托管：</p>
      </div>
        <div class="summaryItem"  :index="index">
          <div class="applicantName">{{item.applicantName}}</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="(item,index) in summaryData"
              :key="index"
              :label="item.applicantName"
              :value="index">
            </el-option>
          </el-select>
          <div class="item" v-if="item.patentExpireList && item.patentExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">专利 &nbsp;&nbsp;{{item.patentExpireList && item.patentExpireList.length ? item.patentExpireList && item.patentExpireList.length : 0}}</span>
            </div>
<!--            <i @click="open(1,index,0 , item.applicantName + 0)" :class="[index1 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              :height="index1 ? 400 : 220"
              :row-key="getRowKeys"
              :key="item.applicantName + 0"
              :ref="item.applicantName + 0"
              :data="item.patentExpireList && item.patentExpireList"
              tooltip-effect="dark"
              style="width: 1010px"
              @selection-change="(val) => handleSelectionChange(val,index,0)">
              <el-table-column
                align="center"
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="专利名称"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{ scope.row.patentName }}</template>
              </el-table-column>
              <el-table-column
                prop="patentApplicationNo"
                label="申请号"
              >
              </el-table-column>
              <el-table-column
                prop="address"
                label="状态"
                show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.patentLawStatus ? '有效' : '失效' }}</template>
              </el-table-column>
            </el-table>
          </div>

          <div class="item" v-if="item.trademarkRenewalExpireList && item.trademarkRenewalExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">商标&nbsp;&nbsp;{{item.trademarkRenewalExpireList && item.trademarkRenewalExpireList.length ? item.trademarkRenewalExpireList && item.trademarkRenewalExpireList.length : 0}}</span>
            </div>
<!--            <i @click="open(1,index,1, item.applicantName + 1)" :class="[index2 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              :height="index2 ? 400 : 220"
              :row-key="getRowKeys"
              :ref="item.applicantName + 1"
              :data="item.trademarkRenewalExpireList && item.trademarkRenewalExpireList"
              tooltip-effect="dark"
              style="width: 1010px"
              @selection-change="(val) => handleSelectionChange(val,index,1)">
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="商标名称"
              >
                <template slot-scope="scope">{{ scope.row.trademarkName }}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="商标图案"
              >
                <template slot-scope="scope">
                  <img style="width: 60px" :src="queryDataUrl(scope.row.trademarkImageUrl)" alt="">
                </template>
              </el-table-column>
              <el-table-column
                prop="trademarkApplyNo"
                label="申请号"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="trademarkStatus"
                label="商标状态"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

          <div class="item" v-if="item.softwareExpireList && item.softwareExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">软著&nbsp;&nbsp;{{item.softwareExpireList && item.softwareExpireList.length ? item.softwareExpireList && item.softwareExpireList.length : 0}}</span>
            </div>
<!--            <i @click="open(1,index,2, item.applicantName + 2)" :class="[index3 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              :height="index3 ? 400 : 220"
              :row-key="getRowKeys"
              :key="item.applicantName + 2"
              :ref="item.applicantName + 2"
              :data="item.softwareExpireList && item.softwareExpireList"
              tooltip-effect="dark"
              style="width: 1010px"
              @selection-change="(val) => handleSelectionChange(val,index,2)">
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="证书编号"
              >
                <template slot-scope="scope">{{ scope.row.softwareNumber }}</template>
              </el-table-column>
              <el-table-column
                prop="softwareName"
                label="软著名称"
              >
              </el-table-column>
              <el-table-column
                prop="softwareType"
                label="证书类型"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="softwareFinishDate"
                label="著作完成时间"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

          <div class="item" v-if="item.certificateExpireList && item.certificateExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">资质&nbsp;&nbsp;{{item.certificateExpireList && item.certificateExpireList.length ? item.certificateExpireList && item.certificateExpireList.length : 0}}</span>
            </div>
<!--            <i @click="open(1,index,3, item.applicantName + 3)" :class="[index4 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              :height="index4 ? 400 : 220"
              :row-key="getRowKeys"
              :key="item.applicantName + 3"
              :ref="item.applicantName + 3"
              :data="item.certificateExpireList && item.certificateExpireList"
              tooltip-effect="dark"
              style="width: 1010px"
              @selection-change="(val) => handleSelectionChange(val,index,3)">
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="证书编号"
              >
                <template slot-scope="scope">{{ scope.row.certificateNumber }}</template>
              </el-table-column>
              <el-table-column
                prop="certificateType"
                label="资质类型"
              >
              </el-table-column>
              <el-table-column
                prop="certificateStatus"
                label="状态"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="certificateIssueDate"
                label="发证日期"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

        </div>
        <el-button style="margin: 20px auto" class="save"  @click="save">保存确定</el-button>
      </div>
    </div>

    <el-dialog
      :visible.sync="ifLoginDialogShow"
      :close-on-click-modal="false"
      custom-class="login_dialog"
      :before-close="closeEvent"
    >
      <login
        @loginDialog="loginDialog"
        :dialog-close="dialogClose"
        @dialogCloseBool="dialogCloseBool"
      />
    </el-dialog>
  </div>
</template>

<script>
  import login from "@/components/common/login"
  import store from "../../store";

  export default {
    name: "index",
    components:{ login },
    data() {
      return {
        item: {},
        index1: false,
        index2: false,
        index3: false,
        index4: false,
        pagination: [],
        tabPosition: 'left',
        applicantName: '',
        activeName: [],
        applicantNameSet: [],
        //trademark(商标)，software(软著)，certificate(资质)trademark(商标)，software(软著)，certificate(资质)
        // custodyTypeSet: 'trademark',
        summaryData: [],
        custodyTypeSet: ['patent', 'trademark', 'software', 'certificate'],
        current: 1,
        size: 20,
        multipleSelection: [],
        applyPersonNameArr: [],
        applicantArr: [],
        ifLoginDialogShow: false,
        dialogClose:false,
        maturityData: []
      }
    },
    mounted() {
      this.editeApplicantName()
      this.getExpireSummary()
      if (this.$route.query.keyword != null && this.$route.query.keyword != ""){
        this.$nextTick(()=>{
          this.remotePeopleMethod(this.$route.query.keyword)
        })
      }
    },
    methods: {
      getExpireSummary(){
        this.$axios.post("/custody/home/custody/expire/summary",{
          "custodyTypeSet":["patent","trademark","software","certificate"]
        }).then(({data}) => {
          if(data.code == 0 && (data.data.length > 0)) {
            this.summaryData = data.data
            this.maturityData = data.data.map(item => {
                item = {
                  applicantName: item.applicantName,
                  custodyTypeSet: ["patent","trademark","software","certificate"],
                  //商标继续托管的id集合
                  trademarkSaveIdSet: [],
                  //商标移除托管的id集合
                  trademarkRemoveIdSet: item.trademarkRenewalExpireList ? item.trademarkRenewalExpireList.map(item => item.platformTmId) : [],
                  //专利继续托管的id集合
                  patentSaveIdSet: [],
                  //专利移除托管的id集合
                  patentRemoveIdSet: item.patentExpireList ? item.patentExpireList.map(item => item.id) : [],
                  //软著继续托管的编号集合
                  softwareSaveNumberSet: [],
                  //软著移除续托管的编号集合
                  softwareRemoveNumberSet: item.softwareExpireList ? item.softwareExpireList.map(item => item.softwareNumber) : [],
                  //资质继续托管的编号集合
                  certificateSaveNumberSet: [],
                  //资质移除续托管的编号集合
                  certificateRemoveNumberSet: item.certificateExpireList ? item.certificateExpireList.map(item => item.certificateNumber) : []
                }
              return item;
            })
          }
        })
      },
      handleFocus(){
        if (!this.tool.getCookie("auth")) {
          this.ifLoginDialogShow = true
        }
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
      loginDialog(data) {
        this.ifLoginDialogShow = data;
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      editeApplicantName(){
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicantArr = data.data
          }
        })
      },
      open(val, item1, item2) {
        switch (item2) {
          case 0:
            this.index2 = false
            this.index3 = false
            this.index4 = false
            if (!this.index1) {
              this.index1 = !this.index1
              // this.pagination[0][item2].size = 20
              // this.pagination[0][item2].current = 1
            } else {
              this.index1 = !this.index1
              // this.pagination[0][item2].size = 5
              // this.pagination[0][item2].current = 1
            }
            ;
            // this.handleCurrentChange(val, item1, item2);
            break;
          case 1:
            this.index1 = false
            this.index3 = false
            this.index4 = false
            if (!this.index2) {
              this.index2 = !this.index2
              // this.pagination[0][item2].size = 20
              // this.pagination[0][item2].current = 1
            } else {
              this.index2 = !this.index2
              // this.pagination[0][item2].size = 5
              // this.pagination[0][item2].current = 1
            }
            // ;this.handleCurrentChange(val, item1, item2);
            break;
          case 2:
            this.index1 = false
            this.index2 = false
            this.index4 = false
            if (!this.index3) {
              this.index3 = !this.index3
              // this.pagination[0][item2].size = 20
              // this.pagination[0][item2].current = 1
            } else {
              this.index3 = !this.index3
              // this.pagination[0][item2].size = 5
              // this.pagination[0][item2].current = 1
            }
            // ;this.handleCurrentChange(val, item1, item2);
            break;
          case 3:
            this.index1 = false
            this.index2 = false
            this.index3 = false
            if (!this.index4) {
              this.index4 = !this.index4
              // this.pagination[0][item2].size = 20
              // this.pagination[0][item2].current = 1
            } else {
              this.index4 = !this.index4
              // this.pagination[0][item2].size = 5
              // this.pagination[0][item2].current = 1
            }
            // ;this.handleCurrentChange(val, item1, item2);
            break;
        }
      },
      optionEvent(val) {
        this.applicantName = val
        this.multipleSelection = []
      },
      remotePeopleMethod(val) {
        if (!this.tool.getCookie("auth")) {
          this.ifLoginDialogShow = true
          return;
        }

        this.applicantName = val
        this.multipleSelection = []
        this.applicantHit(val)
      },
      //根据国家联想申请人名称
      applicantHit(val) {
        this.$axios
          .post("/custody/home/applicant/name/summary", {
            applicantName: val
          }).then(({data}) => {
          if (data.code == 0){
            this.applyPersonNameArr = data.data
            this.$refs.nameSel.focus()
          }
        }).catch(err => {
          this.$message.error(err.data.msg);
        })
      },
      getRowKeys(row) {
        return row.platformTmId
      },
      applicantSave() {
        this.$axios
          .post("/custody/home/custody/expire/save/delete").then(({data}) => {
          if (data.code == 0) {

          }
        })
      },
      save() {
        store.commit("loadingBool", true);
        this.$axios
          .post("/custody/home/custody/expire/save/delete", this.maturityData).then(({data}) => {
          if (data.code == 0) {
            // this.applicantSave()
            setTimeout(() => {
              store.commit("loadingBool", false);
              this.$message({
                message: data.data,
                type: 'success'
              });
              this.$router.push({
                path: '/hostingPlatforms'
              })
            },3000)
          } else {
            this.$message.error(data.data);
          }
        }).catch(err => {
          this.$message.error('托管到期数据删除和保存失败');
        })
      },
      //-----------分页的方法-------------
      handleCurrentChange(val, item1, item2) {
        this.$axios
          .post("/custody/home/applicant/info/summary", {
            applicantNameSet: [this.summaryData[item1].applicantName],
            custodyTypeSet: [this.custodyTypeSet[item2]],
            current: val,
            size: this.pagination[item1][item2].size
          }, {timeout: 60 * 1000 * 5}).then(({data}) => {
          if (data.code == 0) {
            switch (item2) {
              case 0 :
                this.summaryData[item1].patentExpireList = data.data[0].patentExpireList;
                break;
              case 1 :
                this.summaryData[item1].trademarkRenewalExpireList = data.data[0].trademarkRenewalExpireList;
                break;
              case 2 :
                this.summaryData[item1].softwareExpireList = data.data[0].softwareExpireList;
                break;
              case 3 :
                this.summaryData[item1].certificateExpireList = data.data[0].certificateExpireList;
                break;
            }

          }
        })
      },
      //多选的方法
      handleSelectionChange(val, item1, item2) {
        let ids = []
        switch (item2) {
          case 0 :
            ids = val.map(item => item.id)
            this.maturityData[item1].patentSaveIdSet = ids;
            this.maturityData[item1].patentRemoveIdSet = this.maturityData[item1].patentRemoveIdSet.filter(item => !ids.includes(item));
            break;
          case 1 :
            ids = val.map(item => item.platformTmId)
            this.maturityData[item1].trademarkSaveIdSet = ids;
            this.maturityData[item1].trademarkRemoveIdSet = this.maturityData[item1].trademarkRemoveIdSet.filter(item => !ids.includes(item));
            break;
          case 2 :
            ids = val.map(item => item.softwareNumber)
            this.maturityData[item1].softwareSaveNumberSet = ids;
            this.maturityData[item1].softwareRemoveNumberSet = this.maturityData[item1].softwareRemoveNumberSet.filter(item => !ids.includes(item));
            break;
          case 3 :
            ids = val.map(item => item.certificateNumber)
            this.maturityData[item1].certificateSaveNumberSet = ids;
            this.maturityData[item1].certificateRemoveNumberSet = this.maturityData[item1].certificateRemoveNumberSet.filter(item => !ids.includes(item));
            break;
        }
      },
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      //获取申请人名称集合
      determine() {
        ///applicant/update/name
        if(!this.applicantName){
          this.$message({
            message: '请输入您要托管的公司名称',
            type: 'warning'
          });
          return;
        }

        if(this.$route.query.tpye && this.$route.query.tpye == 'edit'){
          this.$axios
            .get("/custody/applicant/update/name",{
              params: {
                applicantName: this.applicantName,
                applicantId: this.applicantArr.filter(item => item.storeFlag == true)[0].applicantId
              }
            }).then(({data}) => {
            if(data.code == 0){
              this.summaryApplicant()
            }else {
              this.$message({
                message: data.msg,
                type: 'warning'
              });
            }
          })
        }else {
          this.summaryApplicant()
        }

      },
      //申请人名称汇总
      summaryApplicant() {
        this.$axios
          .post("/custody/home/applicant/info/summary", {
            applicantNameSet: [this.applicantName],
            custodyTypeSet: this.custodyTypeSet,
            current: this.current,
            size: this.size
          }, {timeout: 60 * 1000 * 5}).then(({data}) => {
          if (data.code == 0) {
            this.summaryData = data.data.map((item, index) => {
              this.activeName.push('patent' + index)
              this.pagination.push([{
                current: this.current,
                size: this.size,
              }, {
                current: this.current,
                size: this.size,
              }, {
                current: this.current,
                size: this.size,
              }, {
                current: this.current,
                size: this.size,
              }])
              return item
            })
          }else {

          }
        }).catch(err => {
          this.$message.error(err.data.msg);
        })
      },
      handleClick(tab, event) {
        this.custodyTypeSet = tab
      }
    },
  }
</script>

<style lang="less" scoped>
  .clearfix_content_option {
    &.el-select-dropdown__item {
      font-size: 18px;
    }
  }

  .hostingPlatform {
    .hostingPlatform_applicantArr{
      position: absolute;
      top: 180px;
      /deep/.el-radio{
        margin-bottom: 15px;
      }
    }

    .content_his {
      height: 105px;
      overflow-y: auto;
      //result-ico
      // 滚动条的宽度
      &::-webkit-scrollbar {
        width: 10px; // 横向滚动条
        height: 10px; // 纵向滚动条 必写
      }
      // 滚动条的滑块
      &::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 53%, .1);
        border-radius: 10px
      }
      &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 53%, .1)
      }
    }

    .hostingPlatform_cen {
      width: 1200px;

      margin: 0 auto;
      background: #FFFFFF;
      overflow: hidden;
      .myContent{
        position: relative;
        width: 1150px;
        margin: 50px 28px;
        text-align: center;

        .myTitle{
          position: absolute;
          display: inline-block;
          top: 0;
          left: 533px;
          font-size: 22px;
          color: #333333;
        }

        .topLeftContent{
          text-align: left;
          background-size: 100% 100%;
          background-image: url("../../assets/images/hostingPLatform/leftTopBG.png");
          height: 260px;
          padding: 80px 0 0 86px;
        }
        .topRightContent{
          text-align: left;
          height: 260px;
          padding: 83px 0 0 9px;
        }
        .bottomLeftContent{
          text-align: left;
          height: 260px;
          padding: 8px 0 0 95px;
        }
        .bottomRightContent{
          text-align: left;
          background-size: 100% 100%;
          background-image: url("../../assets/images/hostingPLatform/rightBottomBG.png");
          height: 260px;
          padding: 8px 0 0 8px;
        }
        .myBorderBox1{
          position: relative;
          height: 172px;
          width: 425px;
          border-radius: 8px;
          h3{
            margin-top: 40px;
            margin-left: 100px;
          }
          p{
            margin-top: 20px;
            margin-left: 100px;
          }
        }
        .myBorderBox2{
          position: relative;
          display: inline-block;
          height: 170px;
          width: 458px;
          box-shadow: 0 0 6px 2px rgba(12,26,76,0.12);
          border-radius: 8px;
          h3{
            margin-top: 40px;
            margin-left: 100px;
          }
          p{
            margin-top: 20px;
            margin-left: 100px;
            margin-right: 30px;
          }
        }
        .myBorderBox3{
          position: relative;
          display: inline-block;
          height: 155px;
          width: 470px;
          box-shadow: 0 0 6px 2px rgba(12,26,76,0.12);
          border-radius: 8px;
          h3{
            margin-top: 30px;
            margin-left: 90px;
          }
          p{
            margin-top: 20px;
            margin-left: 90px;
            margin-right: 30px;
          }
        }
        .myBorderBox4{
          position: relative;
          height: 156px;
          width: 417px;
          border-radius: 8px;
          h3{
            margin-top: 30px;
            margin-left: 100px;
          }
          p{
            margin-top: 20px;
            margin-left: 100px;
          }
        }
        .advantagesContentImage1{
          position: absolute;
          top: 10px;
          left: 20px;
          height: 70px;
          width: 67px;
        }
        .advantagesContentImage2{
          position: absolute;
          top: 45px;
          left: 15px;
          height: 75px;
          width: 67px;
        }
        .advantagesContentImage3{
          position: absolute;
          top: 45px;
          left: 15px;
          height: 75px;
          width: 67px;
        }
        .advantagesContentImage4{
          position: absolute;
          top: 10px;
          left: 15px;
          height: 77px;
          width: 71px;
        }
        h3{
          color: #555555;
          font-size: 16px;
        }
        p{
          color: #7D7D7D;
          font-size: 14px;
        }
      }

      .box-card {
        /*text-align: center;*/
        /*width: 1000px;*/
        padding-bottom: 15px;
        box-sizing: border-box;
        height: 370px;
        background-image: linear-gradient(45deg, #D1D9F1, transparent 70%);
        box-shadow: none;
        border: none;

        .clearfix {
          display: flex;
          flex-direction: column;
          padding: 50px 250px;
          box-sizing: border-box;
          position: relative;
          .clearfix_header {
            font-size: 22px;
            font-weight: bolder;
            color: #444444;
            line-height: 33px;
            letter-spacing: 2px;
            position: relative;
            padding-left: 12px;
            &::before {
              top: 5px;
              left: 0;
              position: absolute;
              content: '';
              width: 5px;
              height: 24px;
              background: #465CFC;
            }
          }
          .clearfix_content {
            width: 680px;
            text-align: right;
            margin-top: 40px;
            /deep/ .el-select {
              .el-icon-circle-close {
                font-size: 24px;
              }
              .el-input__inner {
                font-size: 18px;
              }
              input {
                width: 680px;
                height: 50px;
                background: #FFFFFF;
                border-radius: 12px;
                border: 1px solid #B4C5E5;
              }
            }
            .btn {
              margin-top: 59px;
              width: 115px;
              height: 45px;
              background: #2E54D9;
              border-radius: 8px;
              font-size: 18px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
      }

      .home_top {
        height: 90px;
      }

      .save {
        cursor: pointer;
        width: 98px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2E54D9;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .summaryItem {
        text-align: center;
        width: 1000px;

        .applicantName {
          text-align: left;
          padding-left: 62px;
          font-size: 22px;
          font-weight: 500;
          color: #2E54D9;
          letter-spacing: 1px;
          margin-bottom: 35px;
        }

        .item {
          width: 1098px;
          min-height: 294px;
          padding-bottom: 20px;
          margin-bottom: 36px;
          padding-top: 17px;
          position: relative;
          background: #EFF2FD;
          overflow: hidden;
          border-radius: 12px;
          border: 1px solid #E1E7F2;
          margin-left: 55px;

          .table {
            border-radius: 6px 6px 0px 0px;
            margin: 24px 0 10px 40px;

            /deep/ th.el-table__cell {
              background: #D3DBF8 !important;
            }
          }

          .item_title {
            position: relative;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            padding-left: 14px;
            margin: 0px 0 0 19px;

            &::before {
              position: absolute;
              left: 0;
              top: 5px;
              content: '';
              width: 4px;
              height: 18px;
              background: #465CFC;
            }
          }

          .icon {
            position: absolute;
            font-size: 16px;
            cursor: pointer;
            top: 30px;
            right: 58px;
          }
        }
      }

      .summaryData_title {
        margin: 28px 33px;
        display: flex;

        img {
          width: 20px;
          height: auto;
          margin-right: 9px;
        }

        span {
          font-size: 22px;
          font-weight: 500;
          color: #444444;
          letter-spacing: 1px;
        }
      }
    }

    /*padding-top: ;*/
  }
</style>
